<template>
	<modal class="ClvModalTransliteration" name="ClvModalTransliteration" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="clv_modal">
			<div class="clv_modal_head">
				<div class="clv_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="clv_modal_title">
					<span v-if="clvType == 'addTransliteration'">Добавление переменной транслитерации</span>
					<span v-else-if="clvType == 'editTransliteration'">Редактирование переменной транслитерации</span>
				</div>
			</div>
			<div class="clv_modal_body">
				<div class="clv_modal_info">
					<div v-if="['addTransliteration', 'editTransliteration'].includes(clvType)">
						<div class="clv_modal_info_row">
							<textarea class="clv_textarea small" v-model="clvTextRU" placeholder="Русский вариант перевода html текста"></textarea>
							<div v-html="clvAlertTextRU"></div>
						</div>
						<div class="clv_modal_info_row last">
							<textarea class="clv_textarea small" v-model="clvTextEN" placeholder="Английский вариант перевода html текста"></textarea>
							<div v-html="clvAlertTextEN"></div>
						</div>
					</div>
				</div>
				<div class="clv_modal_alert" v-html="clvAlert"></div>
				<div class="clv_modal_loading" :class="{ hide: !clvIsLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="clv_modal_buttons" :class="{ hide: clvIsLoading }">
					<button type="button" class="clv_button common" @click="close">Закрыть</button>
					<button type="button" class="clv_button common green" @click="axios" v-if="clvType == 'addTransliteration'">Добавить</button>
					<button type="button" class="clv_button common green" @click="axios" v-else-if="clvType == 'editTransliteration'">Сохранить</button>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			clvTemp: '',
			clvData: {},
			clvAlert: '',
			clvAlertTextRU: '',
			clvAlertTextEN: '',
			clvUId: 0,
			clvType: '',
			clvIndex: 0,
			clvTextRU: '',
			clvTextEN: '',
			clvIsLoading: false,
			clvIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.clvTemp = '';
				this.clvData = {};
				this.resetAlerts();
				this.clvUId = event.params.uid;
				this.clvType = event.params.type;
				this.clvIndex = event.params.index;
				this.clvTextRU = '';
				this.clvTextEN = '';
				this.clvIsLoading = false;
				this.clvIsError = false;

				if (this.clvType == 'editTransliteration') {
					if (this.clvUId == 1) {
						this.clvData = this.$parent.clvTransliterationDataFromLanguage[this.clvIndex];
					}
					else if (this.clvUId == 2) {
						this.clvData = this.$parent.clvTransliterationDataFromDatabase[this.clvIndex];
					}
					if (Object.keys(this.clvData).length) {
						this.clvTextRU = this.clvData.ru;
						this.clvTextEN = this.clvData.en;
					}
				}
			},
			resetAlerts: function() {

				this.clvAlert = '';
				this.clvAlertTextRU = '';
				this.clvAlertTextEN = '';
			},
			getError: function(i) {

				this.clvIsError = true;

				if      (i == 1) { this.clvTemp = 'Необходимо указать uid переменной транслитерации.'; }
				else if (i == 2) { this.clvTemp = 'Необходимо указать верный uid переменной транслитерации.'; }
				else if (i == 3) { this.clvTemp = 'Необходимо указать тип переменной транслитерации.'; }
				else if (i == 4) { this.clvTemp = 'Необходимо указать верный тип переменной транслитерации.'; }
				else if (i == 5) { this.clvTemp = 'Необходимо указать индекс переменной транслитерации.'; }
				else if (i == 6) { this.clvTemp = 'Необходимо указать верный индекс переменной транслитерации.'; }
				else if (i == 7) { this.clvTemp = 'Необходимо указать русский вариант перевода html текста.'; }
				else if (i == 8) { this.clvTemp = 'Необходимо указать верный русский вариант перевода html текста.'; }
				else if (i == 9) { this.clvTemp = 'Необходимо указать английский вариант перевода html текста.'; }
				else if (i == 10) { this.clvTemp = 'Необходимо указать верный английский вариант перевода html текста.'; }
				else if (i == 11) { this.clvTemp = 'Тип и индекс переменной транслитерации не найдены в системе.'; }
				else              { this.clvTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><strong>Ошибка!</strong> ' + this.clvTemp + '</div>';
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('clvToken')) {

					var config = {
						url: '/v2/admin/account/transliteration',
						method: 'get', baseURL: _this.$store.state.clvApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('clvToken') }
					};

					_this.resetAlerts();
					_this.clvIsError = false;

					if (['addTransliteration', 'editTransliteration'].includes(_this.clvType)) {
						if (!_this.clvTextRU) {
							_this.clvAlertTextRU = _this.getError(7);
						}
						if (!_this.clvTextEN) {
							_this.clvAlertTextEN = _this.getError(9);
						}
						if (!_this.clvIsError) {
							config.data = { uid: _this.clvUId, type: _this.$parent.clvTransliterationFilter.code, index: _this.clvIndex, text_ru: _this.clvTextRU, text_en: _this.clvTextEN };
							config.method = _this.clvType == 'addTransliteration' ? 'post' : 'patch';
						}
					}

					if (!_this.clvIsError) {
						_this.clvIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (_this.clvType == 'addTransliteration') {
									if (_this.clvUId == 1) {
										_this.$parent.clvTransliterationDataFromLanguage.push({ ru: _this.clvTextRU, en: _this.clvTextEN });
									}
									else if (_this.clvUId == 2) {
										_this.$parent.clvTransliterationDataFromDatabase.push({ ru: _this.clvTextRU, en: _this.clvTextEN });
									}
								}
								else if (_this.clvType == 'editTransliteration') {
									if (_this.clvUId == 1) {
										_this.$parent.clvTransliterationDataFromLanguage.splice(_this.clvIndex, 1, { ru: _this.clvTextRU, en: _this.clvTextEN });
									}
									else if (_this.clvUId == 2) {
										_this.$parent.clvTransliterationDataFromDatabase.splice(_this.clvIndex, 1, { ru: _this.clvTextRU, en: _this.clvTextEN });
									}
								}
								_this.close();
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (['addTransliteration', 'editTransliteration'].includes(_this.clvType)) {
										if      (data.response.data.error == 'UID_IS_EMPTY') { _this.clvAlert = _this.getError(1); }
										else if (data.response.data.error == 'UID_NOT_VALID') { _this.clvAlert = _this.getError(2); }
										else if (data.response.data.error == 'TYPE_IS_EMPTY') { _this.clvAlert = _this.getError(3); }
										else if (data.response.data.error == 'TYPE_NOT_VALID') { _this.clvAlert = _this.getError(4); }
										else if (data.response.data.error == 'INDEX_IS_EMPTY') { _this.clvAlert = _this.getError(5); }
										else if (data.response.data.error == 'INDEX_NOT_VALID') { _this.clvAlert = _this.getError(6); }
										else if (data.response.data.error == 'TEXT_RU_IS_EMPTY') { _this.clvAlertTextRU = _this.getError(7); }
										else if (data.response.data.error == 'TEXT_RU_NOT_VALID') { _this.clvAlertTextRU = _this.getError(8); }
										else if (data.response.data.error == 'TEXT_EN_IS_EMPTY') { _this.clvAlertTextEN = _this.getError(9); }
										else if (data.response.data.error == 'TEXT_EN_NOT_VALID') { _this.clvAlertTextEN = _this.getError(10); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (_this.clvType == 'editTransliteration') {
										if (data.response.data.error == 'TYPE_AND_INDEX_NOT_FOUND') { _this.clvAlert = _this.getError(11); }
									}
								}
							}
							if (!_this.clvIsError) {
								_this.clvAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.clvIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			close: function() {
				this.$modal.hide('ClvModalTransliteration');
			}
		}
	}
</script>
